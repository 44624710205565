import React from 'react';


const HomeContactUs = () => (
    <section className="ftco-section contact-section">
        <div className="container mt-5">
            <div className="row block-9">
                <div className="col-md-4 contact-info ftco-animate">
                    <div className="row">
                        <div className="col-md-12 mb-4">
                            <h2 className="h4">Contact Information</h2>
                        </div>
                        <div className="col-md-12 mb-3">
                            <p><span className={`text-white-50`}>Address:</span>
                                <span className={`text-white`}> Lago Vista Tower B, IMPZ, Dubai, UAE.</span>
                            </p>
                        </div>
                        <div className="col-md-12 mb-3">
                            <p><span className={`text-white-50`}>Phone: </span>
                                <a className={`text-white`}
                                   href="tel:045754114">+971
                                    4 5754114</a></p>
                        </div>
                        <div className="col-md-12 mb-3">
                            <p><span className={`text-white-50`}>Email: </span>
                                <a
                                    className={`text-white`}
                                    href="mailto:admin@nypizza.me">admin@nypizza.me</a>
                            </p>
                        </div>
                        <div className="col-md-12 mb-3">
                            <p><span
                                className={`text-white-50`}>Website: </span>
                                <a
                                    className={`text-white`}
                                    href="https://nypizza.me">nypizza.me</a></p>
                        </div>
                    </div>
                </div>
                <div className="col-md-1"/>
                <div className="col-md-6 ftco-animate">
                    <form action="#" className="contact-form">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <input type="text" className="form-control"
                                           placeholder="Your Name"/>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <input type="text" className="form-control"
                                           placeholder="Your Email"/>
                                </div>
                            </div>
                        </div>
                        <div className="form-group">
                            <input type="text" className="form-control"
                                   placeholder="Subject"/>
                        </div>
                        <div className="form-group">
                            <textarea name="" id="" cols="30" rows="7"
                                      className="form-control"
                                      placeholder="Message"/>
                        </div>
                        <div className="form-group">
                            <input type="submit" value="Send Message"
                                   className="btn btn-primary py-3 px-5 text-white"/>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
);

HomeContactUs.propTypes = {};

HomeContactUs.defaultProps = {};

export default HomeContactUs;
