import React from 'react';


const InfoBar = () => (
    <section className="ftco-intro">
        <div className="container-wrap">
            <div className="wrap d-md-flex">
                <div className="info">
                    <div className="row no-gutters">
                        <div className="col-md-3 d-flex ftco-animate">
                            <div className="icon"><span
                                className="icon-whatsapp"/>
                            </div>
                            <div className="text">
                                <h3>+971 50 5210112</h3>
                                <p>Contact us on what's app.</p>
                            </div>
                        </div>
                        <div className="col-md-3 d-flex ftco-animate">
                            <div className="icon"><span className="icon-phone"/>
                            </div>
                            <div className="text">
                                <h3>+971 4 5754114</h3>
                                <p>Give us a call</p>
                            </div>
                        </div>
                        <div className="col-md-3 d-flex ftco-animate">
                            <div className="icon"><span
                                className="icon-my_location"/></div>
                            <div className="text">
                                <h3>Lago Vista Tower B, IMPZ, Dubai, UAE.</h3>
                                <p>Visit us to get the real taste of American
                                    Pizza.</p>
                            </div>
                        </div>
                        <div className="col-md-3 d-flex ftco-animate">
                            <div className="icon"><span
                                className="icon-clock-o"/></div>
                            <div className="text">
                                <h3>Open throughout the week</h3>
                                <p>12:00 to 11:00 pm, Friday 1:00 to 11:00</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="social d-md-flex pl-md-5 p-4 align-items-center">
                    <ul className="social-icon">
                        {/*<li className="ftco-animate"><a href="#">
                            <span className="icon-twitter"/>
                        </a></li>*/}
                        <li className="ftco-animate">
                            <a href="https://www.facebook.com/nypizza"
                               target={`_blank`}>
                                <span className="icon-facebook"/>
                            </a>
                        </li>
                        <li className="ftco-animate">
                            <a href="https://www.instagram.com/nypizza.me/"
                               target={`_blank`}>
                                <span className="icon-instagram"/>
                            </a>
                        </li>
                        <li className="ftco-animate">
                            <a href="https://wa.me/971505210112"
                               target={`_blank`}>
                                <span className="icon-whatsapp"/>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
);

InfoBar.propTypes = {};

InfoBar.defaultProps = {};

export default InfoBar;
