import React from 'react';
import ourMenuPizza from "../../assets/menu/our.menu.pizza.json"
import ourMenuPasta from "../../assets/menu/our.menu.pasta.json"
import ourMenuSalad from "../../assets/menu/our.menu.salad.json"
import ourMenuStarters from "../../assets/menu/our.menu.starters.json"

const OurMenu = () => (
    <div className="container">
        <div className="row justify-content-center mb-5 pb-3 pt-5">
            <div
                className="col-md-7 heading-section text-center ftco-animate">
                <h2 className="mb-4">Our Menu Pricing</h2>
                <p className="flip">
                    <span className="deg1"/>
                    <span className="deg2"/><span className="deg3"/>
                </p>
                <p className="mt-5">We dint have a long long menu, but what we
                    have is full of awesomeness.</p>
            </div>
        </div>
        <h2>Starters</h2>
        <br/>
        <div className="row">
            {ourMenuStarters.map(item => <div className="col-md-6">
                <div className="pricing-entry d-flex ftco-animate">
                    <div className="img"
                         style={{
                             backgroundImage: `url(` + item.image + `)`
                         }}/>
                    <div className="desc pl-3">
                        <div className="d-flex text align-items-center">
                            <h3><span>{item.name}</span></h3>
                            <span
                                className="price text-white">{item.price} AED</span>
                        </div>
                        <div className="d-block">
                            <p>{item.intro}</p>
                        </div>
                    </div>
                </div>
            </div>)}
        </div>

        <h2>Pizza's</h2>
        <br/>
        <div className="row">
            {ourMenuPizza.map(item => <div className="col-md-6">
                <div className="pricing-entry d-flex ftco-animate">
                    <div className="img"
                         style={{
                             backgroundImage: `url(` + item.image + `)`
                         }}/>
                    <div className="desc pl-3">
                        <div className="d-flex text align-items-center">
                            <h3><span>{item.name}</span></h3>
                            <span
                                className="price text-white">{item.price} AED</span>
                        </div>
                        <div className="d-block">
                            <p>{item.intro}</p>
                        </div>
                    </div>
                </div>
            </div>)}
        </div>

        <h2>Pasta's</h2>
        <br/>
        <div className="row">
            {ourMenuPasta.map(item => <div className="col-md-6">
                <div className="pricing-entry d-flex ftco-animate">
                    <div className="img"
                         style={{
                             backgroundImage: `url(` + item.image + `)`
                         }}/>
                    <div className="desc pl-3">
                        <div className="d-flex text align-items-center">
                            <h3><span>{item.name}</span></h3>
                            <span
                                className="price text-white">{item.price} AED</span>
                        </div>
                        <div className="d-block">
                            <p>{item.intro}</p>
                        </div>
                    </div>
                </div>
            </div>)}
        </div>

        <h2>Salad's</h2>
        <br/>
        <div className="row">
            {ourMenuSalad.map(item => <div className="col-md-6">
                <div className="pricing-entry d-flex ftco-animate">
                    <div className="img"
                         style={{
                             backgroundImage: `url(` + item.image + `)`
                         }}/>
                    <div className="desc pl-3">
                        <div className="d-flex text align-items-center">
                            <h3><span>{item.name}</span></h3>
                            <span
                                className="price text-white">{item.price} AED</span>
                        </div>
                        <div className="d-block">
                            <p>{item.intro}</p>
                        </div>
                    </div>
                </div>
            </div>)}
        </div>
    </div>
);

OurMenu.propTypes = {};

OurMenu.defaultProps = {};

export default OurMenu;
