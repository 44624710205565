import React from 'react';
import MasterLayout from "../../layout/MasterLayout/MasterLayout";
import MenuBanner from "./MenuBanner";
import HomeFeaturedPizza
    from "../../components/HomeFeaturedPizza/HomeFeaturedPizza";
import HotMeals from "../../components/HotMeals/HotMeals";
import InfoBar from "../../components/InfoBar/InfoBar";


const MenuPage = () => (
    <MasterLayout>
        <MenuBanner/>
        <HomeFeaturedPizza/>
        {/*<HotMeals/>*/}
        <InfoBar/>
    </MasterLayout>
);

MenuPage.propTypes = {};

MenuPage.defaultProps = {};

export default MenuPage;
