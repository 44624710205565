import React from 'react';


const HomeCounter = () => (
    <section className="ftco-counter ftco-bg-dark img" id="section-counter"
             style={{
                 backgroundImage: `url(images/bg_2.jpg)`
             }}
             data-stellar-background-ratio="0.5">
        <div className="overlay"/>
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-10">
                    <div className="row">
                        <div
                            className="col-md-6 col-lg-4 d-flex justify-content-center counter-wrap ftco-animate">
                            <div className="block-18 text-center">
                                <div className="text">
                                    <div className="icon">
                                        <span className="flaticon-pizza-1 text-white"/>
                                    </div>
                                    <strong className="number text-white"
                                            data-number="2">0</strong>
                                    <span className={`text-white`}>Pizza Branches</span>
                                </div>
                            </div>
                        </div>
                        {/*<div
                            className="col-md-6 col-lg-3 d-flex justify-content-center counter-wrap ftco-animate">
                            <div className="block-18 text-center">
                                <div className="text">
                                    <div className="icon">
                                        <span className="flaticon-medal text-white"/></div>
                                    <strong className="number text-white"
                                            data-number="0">0</strong>
                                    <span className={`text-white`}>Number of Awards</span>
                                </div>
                            </div>
                        </div>*/}
                        <div
                            className="col-md-6 col-lg-4 d-flex justify-content-center counter-wrap ftco-animate">
                            <div className="block-18 text-center">
                                <div className="text">
                                    <div className="icon">
                                        <span className="flaticon-laugh text-white"/></div>
                                    <strong className="number text-white"
                                            data-number="750">0</strong> +
                                    <span className={`text-white`}>Happy Customer</span>
                                </div>
                            </div>
                        </div>
                        <div
                            className="col-md-6 col-lg-4 d-flex justify-content-center counter-wrap ftco-animate">
                            <div className="block-18 text-center">
                                <div className="text">
                                    <div className="icon">
                                        <span className="flaticon-chef text-white"/></div>
                                    <strong className="number text-white"
                                            data-number="9">0</strong>
                                    <span className={`text-white`}>Staff</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

);

HomeCounter.propTypes = {};

HomeCounter.defaultProps = {};

export default HomeCounter;
