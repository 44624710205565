import React from 'react';
import hotPizzaMealsJson from "../../assets/menu/hot.pizza.meals.json"

const HotPizzaMeals = () => (
    <div>
        <div className="container">
            <div className="row justify-content-center mb-5 pb-3">
                <div
                    className="col-md-7 heading-section ftco-animate text-center">
                    <h2 className="mb-4">Hot Pizza Meals</h2>
                    <p>Here are some of out hottest Pizza, loved and appreciated
                        by a number of individuals.</p>
                </div>
            </div>
        </div>
        <div className="container-wrap">
            <div className="row no-gutters d-flex">
                {hotPizzaMealsJson.map(item => <div
                    className="col-lg-4 d-flex ftco-animate">
                    <div className="services-wrap d-flex">
                        <a href="#" className={`img ` + item.addition_class}
                           style={{
                               backgroundImage: `url(` + item.image + `)`
                           }}/>
                        <div className="text p-4">
                            <h3>{item.name}</h3>
                            <p>{item.intro}</p>
                            <p className="price">
                                <span>{item.price}</span>
                                <a
                                    href="#"
                                    className="ml-2 btn btn-white btn-outline-white">
                                    Order
                                </a>
                            </p>
                        </div>
                    </div>
                </div>)}
            </div>
        </div>
    </div>
);

HotPizzaMeals.propTypes = {};

HotPizzaMeals.defaultProps = {};

export default HotPizzaMeals;
