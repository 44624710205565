import React from 'react';


const AboutSection = () => (
    <section className="ftco-about d-md-flex">
        <div className="one-half img" style={{
            backgroundImage: `url(/images/about.jpg)`
        }}/>
        <div className="one-half ftco-animate">
            <div className="heading-section ftco-animate ">
                <h2 className="mb-4">Welcome to <span
                    className="flaticon-pizza">New York Pizza</span></h2>
            </div>
            <div>
                <p>
                    Pizza is not just a food, it is an emotion for the people
                    who love to eat it. Our tasty
                    pizzas are made in American style, these hand made doughs
                    and cheesy material, stone baked ovens
                    will not let you skip the page before ordering your
                    favourite pizza. We daily make
                    fresh doughs with high quality cheese, and properly selected
                    ingredients makes our pizza outstanding.
                </p>
                <p>
                    The quality, quantity and variety all at the same place with
                    love and flavour for
                    you. You only have to choose, tell us your address, and we
                    are here to deliver.
                </p>
            </div>
        </div>
    </section>
);

AboutSection.propTypes = {};

AboutSection.defaultProps = {};

export default AboutSection;
