import React from "react";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import HomePage from "./pages/HomePage/HomePage";
import AboutPage from "./pages/AboutPage/AboutPage";
import ServicesPage from "./pages/ServicesPage/ServicesPage";
import MenuPage from "./pages/MenuPage/MenuPage";


const Routes = () => (
    <BrowserRouter>
        <Switch>
            <Route exact path="/" component={HomePage}/>
            <Route exact path="/about" component={AboutPage}/>
            <Route exact path="/services" component={ServicesPage}/>
            <Route exact path="/menu" component={MenuPage}/>
        </Switch>
    </BrowserRouter>
);

export default Routes;
