import React from 'react';
import MasterLayout from "../../layout/MasterLayout/MasterLayout";
import HomeSlider from "../../components/HomeSlider/HomeSlider";
import InfoBar from "../../components/InfoBar/InfoBar";
import AboutSection from "../../components/AboutSection/AboutSection";
import Services from "../../components/Services/Services";
import HomeFeaturedPizza
    from "../../components/HomeFeaturedPizza/HomeFeaturedPizza";
import CTA from "../../components/CTA/CTA";


const HomePage = () => (
    <MasterLayout>
        <HomeSlider/>
        <InfoBar/>
        <AboutSection/>
        <Services/>
        <HomeFeaturedPizza/>
        <CTA/>
        {/*<HomeGallery/>
        <HomeCounter/>*/}
    </MasterLayout>
);

HomePage.propTypes = {};

HomePage.defaultProps = {};

export default HomePage;
