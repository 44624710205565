import React from 'react';


const HomeSlider = () => (
    <section className="home-slider owl-carousel img"
             style={{
                 backgroundImage: `url("/images/bg_1.jpg")`
             }}>
        <div className="slider-item">
            <div className="overlay"/>
            <div className="container">
                <div className="row slider-text align-items-center"
                     data-scrollax-parent="true">

                    <div className="col-md-6 col-sm-12 ftco-animate">
                        <span className="subheading">Delicious</span>
                        <h1 className="mb-4">Real Taste of American Pizza</h1>
                        <p className="mb-4 mb-md-5">
                            American style, with hand made doughs and cheesy
                            material
                        </p>
                        <p>
                            <a href="https://order.radyes.co/new_york_pizza"
                               target={`_blank`}
                               className="btn btn-primary p-3 px-xl-4 py-xl-3 text-white mr-3">
                                Order
                                Now</a>
                            <a href="/menu"
                               className="btn btn-white btn-outline-white p-3 px-xl-4 py-xl-3 text-white">View
                                Menu</a>
                        </p>
                    </div>
                    <div className="col-md-6 ftco-animate">
                        <img src="/images/bg_1.png" className="img-fluid"
                             alt=""/>
                    </div>

                </div>
            </div>
        </div>
        <div className="slider-item">
            <div className="overlay"/>
            <div className="container">
                <div className="row slider-text align-items-center"
                     data-scrollax-parent="true">

                    <div
                        className="col-md-6 col-sm-12 order-md-last ftco-animate">
                        <span className="subheading">Sizzling Hot</span>
                        <h1 className="mb-4">Direct from stone Oven to your
                            table</h1>
                        <p className="mb-4 mb-md-5">we value time, and on time
                            you will get your pizza.</p>
                        <p>
                            <a href="https://order.radyes.co/new_york_pizza"
                               target={`_blank`}
                               className="btn btn-primary p-3 px-xl-4 py-xl-3 text-white mr-3">
                                Order Now
                            </a>
                            <a href="/menu"
                               className="btn btn-white btn-outline-white p-3 px-xl-4 py-xl-3 text-white">
                                View Menu
                            </a>
                        </p>
                    </div>
                    <div className="col-md-6 ftco-animate">
                        <img src="/images/bg_2.png" className="img-fluid"
                             alt=""/>
                    </div>

                </div>
            </div>
        </div>
    </section>
);

HomeSlider.propTypes = {};

HomeSlider.defaultProps = {};

export default HomeSlider;
