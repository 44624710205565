import React from 'react';


const Services = () => (
    <section className="ftco-section ftco-services">
        <div className="overlay"/>
        <div className="container">
            <div className="row justify-content-center mb-5 pb-3">
                <div
                    className="col-md-7 heading-section ftco-animate text-center">
                    <h2 className="mb-4">Why Us?</h2>
                    <p>
                        Our expertise not only include to deliver a certain
                        product
                        but also making sure that it
                        comes to you safe and sound without any harm. Also the
                        Pizza gets delivered to you on time
                    </p>
                </div>
            </div>
            <div className="row">
                <div className="col-md-4 ftco-animate">
                    <div className="media d-block text-center block-6 services">
                        <div
                            className="icon d-flex justify-content-center align-items-center mb-5">
                            <span className="flaticon-diet"/>
                        </div>
                        <div className="media-body">
                            <h3 className="heading">Top Notch Ingredients</h3>
                            <p>Every pizza ordered ios prepared by the best
                                possible ingredients available out there,
                                without compromising</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 ftco-animate">
                    <div className="media d-block text-center block-6 services">
                        <div
                            className="icon d-flex justify-content-center align-items-center mb-5">
                            <span className="flaticon-bicycle"/>
                        </div>
                        <div className="media-body">
                            <h3 className="heading">Fastest Delivery</h3>
                            <p>We understand the importance of timely delivery,
                                There for you get you pizza at the earliest
                                possible without a delay of single second.</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 ftco-animate">
                    <div className="media d-block text-center block-6 services">
                        <div
                            className="icon d-flex justify-content-center align-items-center mb-5">
                            <span className="flaticon-pizza-1"/></div>
                        <div className="media-body">
                            <h3 className="heading">Original Recipes</h3>
                            <p>Whatever pizza pushed to oven, is made with 100%
                                genuine recipes without any alteration and
                                tweaking.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
);

Services.propTypes = {};

Services.defaultProps = {};

export default Services;
