import React from 'react';


const CTA = () => (
    <section className="ftco-intro">
        <div className="container-wrap">
            <div className="wrap d-md-flex">
                <div className="info">
                    <div className="row no-gutters">
                        <div className="col-md-6 d-flex ftco-animate">
                            <div className="text">
                                <h3>Order Now...</h3>
                                <p>Order now to have your favourite pizza
                                    delivered at your home and make the
                                    moment special.</p>
                            </div>
                        </div>
                        <div className="col-md-6 d-flex ftco-animate">
                            <div className="text">
                                <a href="https://order.radyes.co/new_york_pizza"
                                   target={`_blank`}
                                   className="btn btn-primary py-3 px-5 text-white">
                                    Order Now
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="social d-md-flex pl-md-5 p-4 align-items-center">
                    <ul className="social-icon">
                        {/* <li className="ftco-animate"><a href="#">
                            <span className="icon-twitter"/>
                        </a></li>*/}
                        <li className="ftco-animate">
                            <a href="https://www.facebook.com/nypizza"
                               target={`_blank`}>
                                <span className="icon-facebook"/>
                            </a>
                        </li>
                        <li className="ftco-animate">
                            <a href="https://www.instagram.com/nypizza.me/"
                               target={`_blank`}>
                                <span className="icon-instagram"/>
                            </a>
                        </li>
                        <li className="ftco-animate">
                            <a href="https://wa.me/971505210112"
                               target={`_blank`}>
                                <span className="icon-whatsapp"/>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
);

CTA.propTypes = {};

CTA.defaultProps = {};

export default CTA;
