import Routes from "./Routes";
import useScript from "./hooks/useScript";

function App() {

    useScript('/js/jquery.min.js')
    useScript('/js/jquery-migrate-3.0.1.min.js')
    useScript('/js/popper.min.js')
    useScript('/js/bootstrap.min.js')
    useScript('/js/jquery.easing.1.3.js')
    useScript('/js/jquery.waypoints.min.js')
    useScript('/js/jquery.stellar.min.js')
    useScript('/js/owl.carousel.min.js')
    useScript('/js/jquery.magnific-popup.min.js')
    useScript('/js/aos.js')
    useScript('/js/jquery.animateNumber.min.js')
    useScript('/js/bootstrap-datepicker.js')
    useScript('/js/jquery.timepicker.min.js')
    useScript('/js/scrollax.min.js')
    useScript('https://maps.googleapis.com/maps/api/js?key=AIzaSyBVWaKrjvy3MaE7SQ74_uJiULgl1JY0H2s&sensor=false')
    useScript('/js/google-map.js')
    useScript('/js/main.js')

    return <Routes/>
}

export default App;
