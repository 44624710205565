import React from 'react';
import MasterLayout from "../../layout/MasterLayout/MasterLayout";
import ServicesBanner from "./ServicesBanner";
import Services from "../../components/Services/Services";
import HotMeals from "../../components/HotMeals/HotMeals";
import InfoBar from "../../components/InfoBar/InfoBar";


const ServicesPage = () => (
    <MasterLayout>
        <ServicesBanner/>
        <Services/>
        {/*<HotMeals/>*/}
        <InfoBar />
    </MasterLayout>
);

ServicesPage.propTypes = {};

ServicesPage.defaultProps = {};

export default ServicesPage;
