import React from 'react';
import AboutBanner from "./AboutBanner";
import MasterLayout from "../../layout/MasterLayout/MasterLayout";
import InfoBar from "../../components/InfoBar/InfoBar";
import AboutSection from "../../components/AboutSection/AboutSection";
import HomeCounter from "../../components/HomeCounter/HomeCounter";


const AboutPage = () => (
    <MasterLayout>
        <AboutBanner/>
        <InfoBar />
        <AboutSection />
        <HomeCounter/>
    </MasterLayout>
);

AboutPage.propTypes = {};

AboutPage.defaultProps = {};

export default AboutPage;
