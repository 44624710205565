import React from 'react';
import HomeContactUs from "../HomeContactUs/HomeContactUs";


const Footer = () => (
    <div>
        <HomeContactUs/>
        <footer className="ftco-footer ftco-section img">
            <div className="overlay"/>
            <div className="container">
                <div className="row mb-5">
                    <div className="col-lg-5 col-md-6 mb-5 mb-md-5">
                        <div className="ftco-footer-widget mb-4">
                            <h2 className="ftco-heading-2">About Us</h2>
                            <p>
                                New York Pizza is a well established pizza
                                franchise serving the delicious taste of real
                                american pizza from 14 years.
                            </p>
                            <ul className="ftco-footer-social list-unstyled float-md-left float-lft mt-5">
                                {/*<li className="ftco-animate">
                                    <a href="#">
                                        <span className="icon-twitter"/>
                                    </a>
                                </li>*/}
                                <li className="ftco-animate">
                                    <a href="https://www.facebook.com/nypizza"
                                       target={`_blank`}>
                                        <span className="icon-facebook"/>
                                    </a>
                                </li>
                                <li className="ftco-animate">
                                    <a href="https://www.instagram.com/nypizza.me/"
                                       target={`_blank`}>
                                        <span className="icon-instagram"/>
                                    </a>
                                </li>
                                <li className="ftco-animate">
                                    <a href="https://wa.me/971505210112"
                                       target={`_blank`}>
                                        <span className="icon-whatsapp"/>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {/*<div className="col-lg-3 col-md-6 mb-5 mb-md-5">
                        <div className="ftco-footer-widget mb-4 ml-md-4">
                            <h2 className="ftco-heading-2">Services</h2>
                            <ul className="list-unstyled">
                                <li><a href="#"
                                       className="py-2 d-block">Cooked</a>
                                </li>
                                <li><a href="#"
                                       className="py-2 d-block">Deliver</a>
                                </li>
                                <li><a href="#" className="py-2 d-block">Quality
                                    Foods</a></li>
                                <li><a href="#"
                                       className="py-2 d-block">Mixed</a>
                                </li>
                            </ul>
                        </div>
                    </div>*/}
                    <div className="col-lg-6 col-md-6 mb-5 mb-md-5">
                        <div className="ftco-footer-widget mb-4">
                            <h2 className="ftco-heading-2">Have a
                                Questions?</h2>
                            <div className="block-23 mb-3">
                                <ul>
                                    <li>
                                    <span
                                        className="icon icon-map-marker"/><span
                                        className="text">Lago Vista Tower B, IMPZ (Production City), Dubai, UAE.</span>
                                    </li>
                                    <li><a href="tel:045754114">
                                        <span className="icon icon-phone"/><span
                                        className="text">+971 4 5754114</span></a>
                                    </li>
                                    <li><a href="https://wa.me/971505210112">
                                        <span
                                            className="icon icon-whatsapp"/><span
                                        className="text">+971 50 5210112</span></a>
                                    </li>
                                    <li>
                                        <a href="mailto:admin@nypizza.me">
                                        <span
                                            className="icon icon-envelope"/><span
                                            className="text">admin@nypizza.me</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 text-center">
                        <p>
                            Copyright &copy;
                            {new Date().getFullYear()}
                            <span className={`ml-1`}>All rights reserved</span>
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    </div>
);

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
