import React from 'react';
import HotPizzaMeals from "../HotPizzaMeals/HotPizzaMeals";
import OurMenu from "../OurMenu/OurMenu";


const HomeFeaturedPizza = () => (
    <section className="ftco-section">
        {/*<HotPizzaMeals/>*/}
        <OurMenu/>
    </section>
);

HomeFeaturedPizza.propTypes = {};

HomeFeaturedPizza.defaultProps = {};

export default HomeFeaturedPizza;
