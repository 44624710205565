import React from 'react';
import logo from '../../assets/logo-white.png'

const Nav = () => (
    <nav
        className="navbar navbar-expand-lg navbar-dark ftco_navbar bg-dark ftco-navbar-light"
        id="ftco-navbar">
        <div className="container">
            <a className="navbar-brand" href="/">
                <img src={logo} alt={`nypizza-logo`} className={`header-logo`}/>
            </a>
            <button className="navbar-toggler" type="button"
                    data-toggle="collapse" data-target="#ftco-nav"
                    aria-controls="ftco-nav" aria-expanded="false"
                    aria-label="Toggle navigation">
                <span className="oi oi-menu"/> Menu
            </button>
            <div className="collapse navbar-collapse" id="ftco-nav">
                <ul className="navbar-nav ml-auto">
                    <li className="nav-item">
                        <a href={`/`} className="nav-link">Home</a>
                    </li>
                    <li className="nav-item">
                        <a href={`/menu`} className="nav-link">Menu</a>
                    </li>
                    <li className="nav-item">
                        <a href={`/services`} className="nav-link">Why Us</a>
                    </li>
                    <li className="nav-item">
                        <a href={`/about`} className="nav-link">About</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
);

Nav.propTypes = {};

Nav.defaultProps = {};

export default Nav;
