import React, {useEffect} from "react";

/**
 *
 * @param url
 */
const useScript = url => {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = url;
        script.async = false;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, [url]);
};

export default useScript;
