import React from 'react';
import Nav from "../../components/Nav/Nav";
import Footer from "../../components/Footer/Footer";


const MasterLayout = ({children}) => (
    <div>
        <Nav/>
        {children}
        <Footer/>
        {/*<div id="ftco-loader" className="show fullscreen">
            <svg className="circular" width="48px" height="48px">
                <circle className="path-bg" cx="24" cy="24" r="22" fill="none"
                        strokeWidth="4" stroke="#eeeeee"/>
                <circle className="path" cx="24" cy="24" r="22" fill="none"
                        strokeWidth="4" strokeMiterlimit="10"
                        stroke="#F96D00"/>
            </svg>
        </div>*/}
    </div>
);

MasterLayout.propTypes = {};

MasterLayout.defaultProps = {};

export default MasterLayout;
